import { useState } from 'react';
import {useNavigate}  from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchCustome } from '../../utiles/fetch';
import { infoSwal,inputMailSwal,waitSwal } from '../../utiles/swal';
import { Input } from "../miscellaneous/input/Input";
import { login } from '../../redux/actions';
import './auth.css';



export const FormLogin = ({setDisplay})=>{

    //const dispatch = useDispatch();

    const navigate = useNavigate();

   
    const [form,setForm] = useState({
        email:'testw@gmail.com',
        pass:'123456'
    });

    const [input, setInput] = useState({
        type:'password',
        check:'fa-square color-disabled-check'
    });

    const changeType = (val)=>{
        let [type,check] = ['password','fa-square color-disabled-check'];
        
        if(val === 'password'){
            type = 'text';
            check = 'fa-check-square color-enabled-check';
        }

        setInput({
            type,
            check
        });
    }

    const sendForm = async()=>{
        navigate('/');
        /*dispatch(login(form.email,form.pass));*/
    }

    const onChange = (e)=>{
        console.log(e.target.name);
        setForm({
            ...form,
            [e.target.name] : e.target.value
        })
    }

    const resetPass = ()=>{
        inputMailSwal(async function(email){
            if (email) {
                try{
                    // waitSwal({html:'Espere...'});
                    // const resp = await fetchCustome({ 
                    //     endpoint : '/auth/reset',
                    //     method :'POST',
                    //     body:{
                    //         email: email.trim().toLowerCase()
                    //     },
                    //     json:true,
                    //     token:false
                    // });

                    // if(resp.error)
                    //     return infoSwal({icon: 'info',title: '',text: "No existe el correo",timer:30000});
                    
                    infoSwal({icon: 'success',title: '',text: "En breve recibiras un correo con las instrucciones para recuperar tu contraseña",timer:30000});
                   
                }
                catch(e){
                    infoSwal({icon: 'error',title: '',text: "Error con el servidor, intentelo más tarde",});
                }
            }
        })
    }

    return (
        <section id="login">
            <div className="datailCard">
                <div className="card">

                   
                    <img src={process.env.PUBLIC_URL + "/assets/logo.png"}/>
            
                    <div className="row" style={{borderTop: '1px solid rgba(0,0,0,.1)'}}>

                        <div className="col-md-12 mt-5">
                            <Input type="mail" placeholder="Email" name="email" value={form.email} onChange={onChange} />
                        </div>

                        <div className="col-md-12 mb-3">
                            <Input type={input.type} placeholder="Contraseña" name="pass" value={form.pass}  onChange={onChange} />
                        </div>

                        <div className="col-5 mb-3 mt-3 d-flex align-items-end">
                            <div style={{position:'absolute',cursor:'pointer',zIndex:3}} onClick={()=>changeType(input.type)}>
                                <i className={`fa ${input.check} me-1`} style={{cursor:'pointer',fontSize:25,color:'#ccc'}}></i>
                                <label className="custom-control-label ml-3" style={{cursor:'pointer',fontSize:12}}>Mostrar</label>
                            </div>
                        </div>

                        <div className="col-7 mb-3">
                            <span className="link-forget" onClick={resetPass}>Olvide mi contraseña <i className="fas fa-info-circle fa-2x"></i></span>
                        </div>

                        <div className="col-md-12 text-center mt-5 mb-3">
                            <button type="button" className="btn btn-primary" onClick={sendForm}>Ingresar</button>
                        </div>

                        <div className="col-md-12 mt-4 text-center" style={{borderTop: '1px solid rgba(0,0,0,.3)'}}>
                            <div className="count">
                                {/* <span className="link-forget" onClick={()=>setDisplay(false)}>Crear cuenta <i className="far fa-arrow-alt-circle-right fa-2x"></i>  </span> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}