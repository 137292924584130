import { useState } from 'react'
import { Navigate }  from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormRegister,FormLogin } from './';

export const SelectForm = ()=>{
    
    const {id} = useSelector( store => store.auth );
    const [display,setDisplay] = useState(true);

    
    if(!!id)//Si esta logeado lo redireccionamos a la pantalla principal de la App
        return <Navigate to="/" />;


    return(

        display

        ?

        <FormLogin setDisplay={setDisplay}/>

        :

        <FormRegister setDisplay={setDisplay} />

    )
}
