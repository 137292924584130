import { useState } from 'react'
// import { useNavigate } from "react-router-dom";
import { Input } from "../miscellaneous/input/Input";
import { infoSwal,waitSwal } from '../../utiles/swal';
import {maskPlate,plate,maskEmail,email} from '../../utiles/validaciones';
import { fetchCustome } from '../../utiles/fetch';
import './auth.css';

export const FormRegister = ({setDisplay})=>{

    // const navigate = useNavigate();

    const [form,setForm] = useState({
        email:'',
        number_plate:''
    });

   
    const sendForm = async ()=>{

        if(plate(form.number_plate))
            return infoSwal({title:'Número de placa',text:'Deben ser 8 caracteres alfanumericos'});
        if(email(form.email))
            return infoSwal({title:'Email',text:'Debe ser un email valido'});

        waitSwal();

        try{
            const resp = await fetchCustome( { endpoint:`/auth/register`,method:'POST',body:{email:form.email.trim(),number_plate:form.number_plate.trim()},json:true,token:false } );

            if(resp.length === 0)
                return infoSwal({title:'Número placa',text:'El número de placa no existe'});

            if(resp.error){
                if(resp.error.email)
                    return infoSwal({title:'Email',text:'El email ya existe, registra uno diferente'});
                if(resp.error.number_plate)
                    return infoSwal({title:'Número placa',text:'El número de placa no existe'});
            }
            
            infoSwal({title:'Revisa tu email',text:'Recibiras tus credenciales para acceder al sistema'});
            setDisplay(true);
        }
        catch(e){
            console.log(e);
            infoSwal({title:'Error',text:'Intentalo más tarde'});
        }

        // /navigate('/login');
    }

    const onChange = (e)=>{
       
        let value = '';

        if(e.target.name === "number_plate")
            value = maskPlate(e.target.value.toUpperCase());
        else
            value = maskEmail(e.target.value.toLowerCase());

        setForm({
            ...form,
            [e.target.name] : value
        })
    }

    return (
        <section id="login">
            <div className="datailCard">
                <div className="card">

                    <div className="logo icon">
                        <i className="fas fa-paw"></i>
                        <i className="fas fa-paw shadow_"></i>
                    </div>

                    <h3 className="position-relative text-center mt-2"><span className="tangerine-bold logo-kira">K</span>ira Find <span style={{color:'#fff'}}>Me</span></h3>
            
                    <div className="row">

                        <div className="col-md-12 mt-2">
                            <p className="text-center">NUEVA CUENTA</p>
                        </div>

                        <div className="col-md-12 mt-2">
                            <p>
                                Si no tienes una cuenta puedes registrar la nueva placa desde esta pantalla. Si ya tienes una cuenta y quieres vincular la nueva placa
                                a tu cuenta existente inicia sesión y en la opción de nueva mascota podrás registrarla.
                            </p>
                        </div>

                        <div className="col-md-12 mt-2">
                            <Input type="text" placeholder="Número placa" name="number_plate" value={form.number_plate} onChange={onChange} />
                        </div>

                        <div className="col-md-12 mt-2">
                            <Input type="mail" placeholder="Email" name="email" value={form.email} onChange={onChange} />
                        </div>

                        <div className="col-md-12 text-center mt-4">
                            <button type="button" className="btn btn-primary" onClick={sendForm}>Enviar</button>
                        </div>

                        <div className="col-md-12 mt-4 text-center">
                            <div className="count">
                                <span className="link-forget" onClick={()=>setDisplay(true)}><i className="far fa-arrow-alt-circle-left fa-2x"></i> Iniciar sesión</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}