

export const Footer = ()=>{
    return(
        <footer id="footer">
            <div className="container">
                <div className="copyright">
                   
                </div>
                <div className="credits">
                    
                </div>
            </div>
        </footer>
    )
}