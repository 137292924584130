import { useContext,useState,useRef} from "react";
import { ContextAdvance } from "./ProviderAdvance";
import {files,options} from "./bdTemp"
import {PdfViwCrow} from "../miscellaneous/viewerPdfCrow/PdfViwCrow"
import { advanceSwal } from "../../utiles/swal";



export const Paso2 = ()=>{
    return(
        <>
            <Seccion1 />
            <Seccion2 />
        </>
    )
}


const Seccion1 = ()=>{
    //Control del avance de porcentaje
    const {avance,setAvance} = useContext(ContextAdvance);
   

    const avanceIncrement = ()=>{
        if(avance >= 1 && avance <=2){
            if(avance === 1)
                setAvance(2)
            else
                setAvance(1)
        }
    }

    return(
        <div className='row mt-3 mb-3 seccion'>
            <div className='col-9 text-start'>
                <span className="text-uppercase title">Marcar al finalizar la revisión</span>
            </div>
            <div className='col-3 text-end'>
                <i className={`far fa-2x ${avance > 1 ? 'fa-check-square' : 'fa-square'}`} style={{cursor:'pointer', padding:3,color:'rgb(30, 48, 80)'}} onClick={avanceIncrement}></i>
            </div>
            <div className='col-12'>
                <hr />
            </div>
        </div>
    )
}

const Seccion2 = ()=>{
    const [listFiles] = useState(files);
    const [btnViewer,setBtnViewer] = useState(false);
    const url = useRef(null);

    const showPdfViwCrow = (url_)=>{
        url.current = url_
        setBtnViewer(true);
    }
   

    return(
        <>
            {
                listFiles.map( (file,index) => <Archivo key={file.id} file={file} index={index} showPdfViwCrow={showPdfViwCrow}/> )
                
            }

            {
                btnViewer && <PdfViwCrow close={setBtnViewer} url={url}/>
            }

           
               
        </>
    )
}


const Archivo = ({index, file ,showPdfViwCrow})=>{
    //control para el show/hide de las pestañas
    const [showHide,setShowHide] = useState(true);

    return(
        <div className="row mb-3 pt-3 seccion" >
            <div className='col-9 text-start text-truncate'>
                {index + 1}.-{file.name}
            </div>

            <div className='col-3 text-end'>
                <i className={`fas fa-2x ${showHide ? 'fa-caret-square-up' : 'fa-caret-square-down'}`} style={{cursor:'pointer', padding:3}} onClick={()=>setShowHide(!showHide)}></i>
            </div>

            {
                showHide

                &&

                <>
                
                <div className='col-12 mt-2 mb-2'>
                    <textarea className="form-control" placeholder="..."/>
                </div>

                <div className='col-6 text-start mb-2'>
                    <button className="btn btn-outline-success me-2" onClick={()=>advanceSwal(file.name, file.url)}>
                        <i className="fas fa-download"></i>
                    </button>
                    <button className="btn btn-outline-primary me-2" onClick={()=>showPdfViwCrow(file.url)} >
                        <i className="fas fa-search"></i>
                    </button>
                </div>

                <div className='col-6 mb-2 text-end'>
                    <button type="button" className="btn btn-outline-dark">Guardar</button>
                </div>

                </>

            }

            

            <div className='col-12'>
                <hr />
            </div>
        </div>
    )
}


