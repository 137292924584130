
import { RoutApp } from './routes/RoutApp';
import { Provider } from 'react-redux';
import { ChangeScrollPositionProvider } from './contexts/ContextChangeScrollPosition';
import { store } from './redux/store/store';
import './index.css';
import './components/layout/layout.css';
import { ChangeWidthProvider } from './contexts/ContextChangeWidth';



function App() {

  return (
    <ChangeScrollPositionProvider>
      <ChangeWidthProvider>
      <Provider store={store}>
        <RoutApp />
      </Provider>
      </ChangeWidthProvider>
    </ChangeScrollPositionProvider>
   
  );
}

export default App;
