export const files = [
    {
        id:1,
        id_options: 1,
        name:"Documento nombre 1",
        url: "https://dalton.empresas-web.com/files/file.pdf"
    },
    {
        id:2,
        id_options: 2,
        name:"Documento nombre 2",
        url: "https://dalton.empresas-web.com/files/file.pdf"
    }
]

export const options = [
    {
        id:1,
        name:"Documento nombre 1"
    },
    {
        id:2,
        name:"Documento nombre 2"
    },
    {
        id:3,
        name:"Documento nombre 3"
    }
]