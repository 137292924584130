import {useState,useEffect,useRef} from 'react';
import { Link,useLocation,useNavigate } from "react-router-dom";
import {questionConfirmate} from '../../utiles/swal';

export const Header = ()=>{

    //Debemos saber en que pagina nos encontramos via url
    const {pathname:location} = useLocation();

    //Controlamos el click del botón para mostrar u ocultar el sidebar
    const [toggleButton,setToggleButton] = useState(true);
    //Referencia del sidebar
    const refSideBar = useRef(null);

    //Controlamos que opción del menú se active
    const [optionActive,setOptionActive] = useState({
        '/mis-mascotas':'active'
    });

    //Una vez cargada la pagina verificamos en que módulo nos encontramos
    useEffect(() => {
        setOptionActive({[location] :'active'});
    },[location]);

    /*Al dar click fuera del menú debe cerrarse, sólo en el caso de dispositivos mobiles*/
    useEffect(()=>{
        const handleClickOutside = (event)=> {
            if (refSideBar.current && !refSideBar.current.contains(event.target)) 
                setToggleButton(true);
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);
    },[]);
   
    return(

        <header id="header" ref={refSideBar} className={`${!toggleButton && 'show' }`}>
            <div className="d-flex flex-column">
                <Menu 
                    optionActive={optionActive}
                    setOptionActive={setOptionActive}
                    setToggleButton={setToggleButton}
                />
                <ButtonToggleMenu toggleButton={toggleButton} setToggleButton={setToggleButton}/>

            </div>
        </header>

    )
}


const Menu = ({optionActive,setOptionActive,setToggleButton})=>{

    const navigate = useNavigate();
    
    const goTo = (location)=>{
        setToggleButton(true);
        setOptionActive({[location] :'active'})
    }   

    const logout = (e)=>{
        e.preventDefault();

        questionConfirmate(`¿ Deseas cerrar la sesión ?` ,(resp)=>{
            if(resp){
                navigate('/logout');
            }
        });
    }

    return(
        <>
            <Profile />

            <nav className="nav-menu">
                <ul>
                    <li className={optionActive['/documentos']}><Link className="link" onClick={()=>goTo('/documentos')} to="documentos"><i className="fas fa-folder"></i> Documentos</Link></li>
                    <li className={optionActive['/cuenta']}><Link className="link" onClick={()=>goTo('/cuenta')} to="cuenta"><i className="fas fa-user"></i> Perfil</Link></li>
                    <li className={optionActive['/logout']}><Link className="link" onClick={logout} to=""><i className="fas fa-lock"></i> Cerrar sesión</Link></li>
                </ul>
            </nav>

        </>  
    )
}


const logo = process.env.PUBLIC_URL + '/assets/logo.png'
const Profile = ()=>{
    return(
        <div className="profile pt-2">
            <img src={logo} alt="logo"/>
        </div>
    )
}

const ButtonToggleMenu = ({toggleButton,setToggleButton})=>{

    const onClick = ()=>{
        setToggleButton(!toggleButton);
    }

    return(
        <button type="button" className="mobile-nav-toggle d-xl-none" onClick={onClick}><i className={`fas ${toggleButton ? 'fa-bars' : 'fa-times'}`}></i></button>
    )
}

