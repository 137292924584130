import Swal from 'sweetalert2';
import fileDownload from 'js-file-download';

export const infoSwal = (params)=>{
    const {
        icon = 'info',
        title = '',
        text = '',
        showConfirmButton = true,
        confirmButtonColor = '#3085d6',
        confirmButtonText = 'Aceptar',
        timer = 10000
    } = params;

    Swal.fire({
        icon,
        title,
        text,
        timer,
        showConfirmButton,
        confirmButtonColor,
        confirmButtonText,
        allowOutsideClick: false
    });
}

export const waitSwal = ()=>{
    Swal.fire({
        title: 'Espere...',
        html: '',
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
        }
    });
}

export const inputMailSwal =async (callback)=>{
    const { value: email } = await Swal.fire({
        title: 'Recuperar contraseña',
        input: 'email',
        inputLabel: 'Escribe tu email',
        inputPlaceholder: '...',
    });
    callback(email);
}

export const mixinSwal = (params)=>{

    const {
        icon,
        title=''
    } = params

    Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true
    }).fire({
        icon,
        title
    });
}

export const closeSwal = ()=>{
    Swal.close();
}

export const deleteConfirmate = (key,text,callback)=>{
    Swal.fire({
        icon:"question",
        title:"",
        text,
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        confirmButtonColor: '#3085d6',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
            parseInt(login) === parseInt(key) ? callback(true) : callback(false);
        }
      });
}

export const questionConfirmate = (text,callback)=>{
    Swal.fire({
        title: 'Confirma',
        text,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí'
      }).then((result) => {
          callback(result.isConfirmed);
      })
}

export const advanceSwal =(name,url)=>{

    Swal.fire({
      title: "Descargando archivo",
      html: `<b></b> de <span></span>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
      `,
      timer: 0,
      timerProgressBar: true,
      didOpen: async () => {
        Swal.showLoading();
        const progreso = Swal.getPopup().querySelector("b");
        const total = Swal.getPopup().querySelector("span");
        const bar = Swal.getPopup().querySelector(".progress-bar");
        await downloadCrow(name,url,progreso,total,bar)
      },

      willClose: () => {
        console.log('fin');
      }

    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });
}

const downloadCrow = async(name,url,progreso,total,bar)=>{

    const type = [
        'video/mp4',
        'application/x-rar-compressed',
        'application/pdf'
    ];

    let bytesDownload = 0;
    const buffer = [];
    const res = await fetch(url);
    const contentLength = res.headers.get('Content-Length');
    const contentType = res.headers.get('Content-Type');

    // if(!type.includes(contentType))
    //   return console.log('Error');

    total.textContent = formatBytes(contentLength);

    await res.body.pipeTo(new WritableStream({
      write(chunk) {
        bytesDownload += chunk.byteLength;
        buffer.push(chunk);
        const porcentaje = Math.round(bytesDownload/contentLength*100);
        bar.setAttribute("style", `width: ${porcentaje}%;`);
        progreso.textContent = `${formatBytes(bytesDownload)} (${porcentaje}%)`;
      }
    }));

    const bufferTotal = concatArrayBuffers(buffer);
    const blob = new Blob([bufferTotal]);
    //const ext = contentType.split('/').reverse()[0];
    let ext = url.split('.');
    ext = ext[ext.length - 1];

    await download(name,blob,ext);

    setTimeout(()=>{
        Swal.close();
    },1000);

}

const formatBytes = (bytes, decimals = 2) =>{
    if (bytes === 0) 
    return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const concatArrayBuffers = (chunks) => {
    const result = new Uint8Array(chunks.reduce((a, c) => a + c.length, 0));
  
    let offset = 0;
    for (const chunk of chunks) {
        result.set(chunk, offset);
        offset += chunk.length;
    }

    return result;
}

const download = async (name,blob,ext)=>{
    // let resp = await fetch(url)
    // resp = await resp.blob();
    fileDownload(blob, `${name}.${ext}`);
}