import { createContext, useState } from 'react';


export const ContextAdvance = createContext();

export const AdvanceProvider = ({children})=>{

    const [avance, setAvance] = useState(0);

    return (
        <ContextAdvance.Provider value={{
            avance,
            setAvance
        }}>
            {children}
        </ContextAdvance.Provider>
    )
}