import {useEffect,useState,useRef,useCallback} from 'react';
import {maskNumbers,maskLettersSpace,maskDireccion,maskLink,link} from '../../utiles/validaciones';
import {infoSwal,waitSwal,mixinSwal} from '../../utiles/swal';
import { fetchCustome } from '../../utiles/fetch';


export const useUser = (getData)=>{

    const [resp,setResp] = useState({
        load:true,
        data:{}
    });

    const isMounted = useRef(true);

    const data = useCallback(async()=>{

        const resp = await fetchCustome( { endpoint : `/users/getUser` } );
     
        if(!isMounted.current)
        return;

        setResp({
            load:false,
            data:resp
        });

    },[]);

    useEffect(() => {

        if(getData)//Sólo para datos generales llamao al server para obtener los datos
            data();

        return () => {
            isMounted.current = false;
        }
        
    }, [data]);


    //Detectamos los cambios en los inputs y validamos su formato
    const onChange = (e)=>{

        let value = '';

        if(e.target.name === "name")
            value = maskLettersSpace(e.target.value);
        else if(e.target.name === "phone")
            value = maskNumbers(e.target.value);
        else if(e.target.name === "adress")
            value = maskDireccion(e.target.value);
        else if(e.target.name === "facebook")
            value = maskLink(e.target.value);
        else
            value = e.target.value;


        setResp({
            ...resp,
            data:{
                ...resp.data,
                [e.target.name] : value
            }
        })
    }

    //Validamos nuestro formulario y lo enviamos al backend
    const onUpdate = async()=>{

        if(link(resp.data.facebook)){
            infoSwal({title:'Facebook',text:'No es un enlace válido'});
            return false;
        }


        waitSwal();

        const resp2 = await fetchCustome( { endpoint : `/users/updateUser`,body:resp.data,method:'PUT',json:true } );
        
        if(resp2.id){
            mixinSwal({icon:"success",title:"Información actualizada"});
            return true;
        }
     
        mixinSwal({icon:"error",title:"Ocurrio un error"});
        return false;
       
    }

    const onUpdatePass = async(password)=>{
        waitSwal();

        const resp = await fetchCustome( { endpoint : `/users/updatePass`,body:{password},method:'PUT',json:true } );
        console.log(resp);
        
        if(resp.id){
            mixinSwal({icon:"success",title:"Contraseña actualizada"});
            return true;
        }
     
        mixinSwal({icon:"error",title:"Ocurrio un error"});
        return false;
    }

    return{
        resp,
        onChange,
        onUpdate,
        onUpdatePass
    }

}














