import { Documentos } from "../components/moduloDocumentos/Documentos";
import { MisDatos } from "../components/moduloPerfil/Perfil";


export const router = ()=>{
   return [
        {
            path: '/documentos',
            element: <Documentos />
        },
        {
            path: '/cuenta',
            element: <MisDatos />
        }
    ]
}