import {ModalXl} from "../miscellaneous/modal/Modal";
import { Paso1 } from "./Paso1";
import { Paso2 } from "./Paso2"

export const Modal = ({modal,setModal,data})=>{

    const options = (key)=>{
        switch (key) {
            case 1:
                return <Paso1 />
            case 2:
                return <Paso2 />
            case 3:
                return <Paso3 />
            case 4:
                return <Paso4 />
            case 5:
                return <Paso5 />
            case 6:
                return <Paso6 />
            default:
                return <h1>NO EXISTE LA OPCIÓN</h1>
        }
    }

    return(
        <ModalXl modal={modal} setModal={setModal}>
            <div className="modal-crow-header text-center">
                <h5>{data.current.number} {data.current.title} </h5>
            </div>

            {
                options(data.current.number)
            }
            
        </ModalXl>
    )
}



const Paso3 = ()=>{
    return (
        <h1>Paso 3</h1>
    )
}

const Paso4 = ()=>{
    return (
        <h1>Paso 4</h1>
    )
}

const Paso5 = ()=>{
    return (
        <h1>Paso 5</h1>
    )
}

const Paso6 = ()=>{
    return (
        <h1>Paso 6</h1>
    )
}