
import {Navigate}  from 'react-router-dom';
import { useSelector } from 'react-redux';

export const Auth = ({children}) => {

    // const { id } = useSelector( store => store.auth );

    // return  !!id ? children : <Navigate to="login"/>

    return children;

}
