import { v4 as uuidV4  } from 'uuid';
import "./input.css";


export const Input = ({type,placeholder="",disabled=false,value="",onChange=null,name="",list=[],required=false})=>{
    const id = uuidV4();
    return(
        <div className="input-container">
            <SwitchInput type={type} disabled={disabled} value={value} onChange={onChange} name={name} list={list} id={id} />
            <label htmlFor={id} className={ (value ? 'contain' : '') || (value === 0 ? 'contain' : '')  }>{placeholder}</label>
            {
                (required && !disabled) 
                &&
                <i className="fas fa-info-circle required"></i>
            }
        </div>
    );
}

const SwitchInput = ({type,disabled,value,onChange,name,list,id})=>{
    switch (type) {
        case "text":
            return <input type="text" disabled={disabled} value={value} onChange={onChange} name={name} id={id} />
        case "date":
            return <input type="date" disabled={disabled} value={value} onChange={onChange} name={name} id={id} />
        case "password":
            return <input type="password" disabled={disabled} value={value} onChange={onChange} name={name} id={id} />
        case "select":
            return(
                    <select disabled={disabled} value={value} onChange={onChange} name={name} list={list} id={id}>
                        <option></option>
                        {
                            list.map( e => <option key={e.id} value={e.id}>{e.name}</option>)
                        }

                    </select>
            )
        case "textarea":
            return <textarea onChange={onChange} name={name} value={value} disabled={disabled} id={id}></textarea>
        default:
            return <input type="text" disabled={disabled} value={value} onChange={onChange} name={name}  id={id} />
    }
}