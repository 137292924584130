import { useState,useRef,useCallback,useEffect } from "react";
import { fetchCustome } from '../../utiles/fetch'; 


const BD = [
    {
        id:1,
        name : 'Uriel',
        img : '',
        sex : 1
    }
]


export const usePets = ()=>{

    const [resp,setResp] = useState({
        load:true,
        data:[]
    });

    const isMounted = useRef(true);

    const data = ()=>{
        return new Promise((resolve, reject) => {
            setTimeout(()=>{
                resolve(BD)
            },500)
        })
    }

    useEffect(()=>{

        data()
        .then(resp => {
            setResp({
                loda:false,
                data: resp
            })
        })
        .catch(console.log)

    },[])

    // const data = useCallback(async()=>{
    //     let resp = await fetchCustome( { endpoint : `/pets/getPets` } );

    //     if(!isMounted.current)
    //         return;

    //     setResp({
    //         load:false,
    //         data:resp
    //     });

    // },[]);


    // useEffect(()=>{
    //     data();
    //     return () => {
    //         isMounted.current = false;
    //     }
    // },[data]);




    return {
        resp,
        setResp
    }
}