import {useEffect, useRef, useState} from 'react';
import { useUser } from './usePerfil';
import { Input } from '../miscellaneous/input/Input';
import { Load2 } from '../miscellaneous/load2/Load2';
import {questionConfirmate,infoSwal} from '../../utiles/swal';
import {pass as passvalidate} from '../../utiles/validaciones';
import './perfil.css';

export const MisDatos = ()=>{

    return(
        <section id="misDatos">
            <div className="container" >
                <div className="section-title">
                    <h2>Mis datos</h2>
                </div>

                <div className="row">
                    {/* <DetailCard /> */}
                    <DetailCard2 />
                </div>

            </div>
        </section>
    );
}

const DetailCard = ()=>{

    const {resp,onChange,onUpdate} = useUser(true);

    const [disabledForm,setDisabledForm] = useState(true);
   
    const formSave = async()=>{
          
            if(await onUpdate()){
                setDisabledForm(true);
            }
    }

    const editForm = ()=>{
        questionConfirmate(`Deseas actualizar la información` ,async(resp)=>{
            if(resp){
                setDisabledForm(false);
            }
        });
    }

    const openFaceBook = (url)=>{
        // if(url === "" || !disabledForm)
        //     return;
        // window.open(url, '_blank');
    }

    const openWhatsApp = (phone)=>{
        // if(phone === "" || !disabledForm)
        //     return;
        // window.open(`https://api.whatsapp.com/send?phone=${phone}`, '_blank');
    }

    

    return(
        <>
            

            {
                
                resp.load
            
                ?

                <Load2 />

                :
            
                <div className="datailCard mb-4">
                    <div className="card">

                        <h5 className="text-center">DATOS GENERALES</h5>

                        <div className="row mt-2">
                            
                            <div className="col-md-12">
                                <Input type="text" placeholder="Nombre del propietario" value={resp.data.name} disabled={disabledForm} onChange={onChange} name="name" />
                            </div>

                            <div className="col-md-4 position-relative" onClick={()=>openWhatsApp(resp.data.phone)}>
                                {/* {
                                    disabledForm

                                    &&

                                    <div className='urlOver'></div>
                                } */}
                                <Input type="text" placeholder="Teléfono" value={resp.data.phone} disabled={disabledForm} onChange={onChange} name="phone" />
                            </div>

                            <div className="col-md-4">
                                <Input type="text" placeholder="E-mail" value={resp.data.email} disabled={true} />
                            </div>

                            <div className="col-md-4 position-relative" onClick={()=>openFaceBook(resp.data.facebook)}>
                                {/* {
                                    disabledForm

                                    &&

                                    <div className='urlOver'></div>
                                } */}
                                <Input type="text" placeholder="Facebook" value={resp.data.facebook} disabled={disabledForm} onChange={onChange} name="facebook" />
                            </div>

                            <div className="col-md-12 mb-2">
                                <Input type="textarea" placeholder="Dirección" value={resp.data.adress} disabled={disabledForm} onChange={onChange} name="adress" />
                            </div>

                            <div className="col-md-12 text-center mt-3">
                            {
                                    disabledForm
                                    ?
                                    <button type="button" className="btn btn-secondary" onClick={editForm}>Editar datos</button>
                                    :
                                    <button type="button" className="btn btn-dark" onClick={formSave}>Guardar datos</button>
                            }
                            
                            </div>
                        
                        </div>

                    </div>
                </div>
            }
        </>
    )
}

const DetailCard2 = ()=>{

    const {onUpdatePass} = useUser(false);
   
    const [form,setForm] = useState({
        pass:'',
        pass2:'',
        reset:false
    })

    const updateForm =async()=>{


        if(form.pass !== form.pass2)
            return infoSwal({icon: 'info',title: 'Contraseñas incorrectas',text: `La contraseña y la confirmación deben ser identicas`,timer:30000});
        
        if(passvalidate(form.pass))                                                                                                                                                                                                                    
            return infoSwal({icon: 'info',title: '',text: `La contraseña debe de tener mínimo 10 y máximo 15 caracteres; al menos 1 número,1 mínuscula, 1 máyuscula y alguno de los siguientes caracteres especiales @ $ ! % * ? . _` ,timer:30000});

        if(await onUpdatePass(form.pass)){
            setForm({
                pass:'',
                pass2:'',
                reset:true
            });
        }
    }

    const onChange = (e)=>{
        setForm({
            ...form,
            reset:false,
            [e.target.name] : e.target.value
        })
    }

    return(
       
        <div className="datailCard mb-4">
            <div className="card">

                <h5 className="text-center">CAMBIAR CONTRASEÑA</h5>

                <div className="row mt-2">
                    <InputPass placeholder="Nueva contraseña" value={form.pass} name="pass" onChange={onChange} reset={form.reset} />
                    <InputPass placeholder="Confirmar contraseña" value={form.pass2} name="pass2" onChange={onChange} reset={form.reset} />
                    <div className="col-md-12 text-center mt-3">
                        <button type="button" className="btn btn-dark" onClick={updateForm}>Guardar datos</button>
                    </div>
                </div>

            </div>
        </div>
           
    )
}

const InputPass = ({placeholder,value,name,onChange,reset})=>{

    const [input, setInput] = useState({
        type:'password',
        check: 'fa-square color-disabled-check'
    });

    useEffect(()=>{
        if(reset)
            resetInput();
    },[reset])

    const changeType = (val)=>{
        let [type,check] = ['password','fa-square color-disabled-check'];
        
        if(val === 'password'){
            type = 'text';
            check = 'fa-check-square color-enabled-check';
        }

        setInput({
            type,
            check
        });
    }

    const resetInput = ()=>{
        setInput({
            type:'password',
            check: 'fa-square color-disabled-check'
        });
    }

    return(
        <>
            <div className="col-md-4"></div>
            <div className="col-md-4">
                <Input type={input.type} placeholder={placeholder} value={value}  onChange={onChange} name={name} />
            </div>
            <div className="col-md-4 mt-2 show-passwd">
                <div style={{position:'absolute',cursor:'pointer',zIndex:3}} onClick={()=>changeType(input.type)}>
                    <i className={`fa ${input.check} me-1`} style={{cursor:'pointer',fontSize:25,color:'#ccc'}}></i>
                    <label className="custom-control-label ml-3" style={{cursor:'pointer',fontSize:12}}>Mostrar</label>
                </div>
            </div>
        </>
    )
}