
import { useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useRoutes, Navigate, Outlet} from "react-router-dom";
import { router } from './router';
import { Footer,Header } from '../components/layout';
import { ButtonFloat } from "../components/miscellaneous/buttonFloat/ButtonFloatComponent";
import { SelectForm,Logout,Auth } from "../components/auth";
import { checkLogin } from '../redux/actions';
import { Load } from '../components/miscellaneous/load/Load';


export const RoutApp = ()=>{

   const { preload : {active} } = useSelector( store => store );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkLogin());//verificamos si estamos logeados o no
    }, [dispatch]);

    const all_routes = [
        {
            element:(
                <Auth>
                    <Header />
                    <main id="main">
                        <Outlet />
                        <ButtonFloat />
                    </main>
                    <Footer />
                </Auth>
            ),
            children: router()
        },
        {
            path: '/login',
            element:<SelectForm />
        },
        {
            path: '/logout',
            element: <Logout />,
        },
        {
            path: '/',
            element: <Navigate to="/documentos" />,
        },
        { 
            path: '*',
            element: <Navigate to="/" />,
        }
    ];

    const all_pages = useRoutes(all_routes)//Nos crea <Routes> {...<Route></Route>} </Routes>


   return(
        <>
            { active ? <Load /> :  all_pages }
           
        </>
   )

}