import { fetchCustome } from '../../utiles/fetch';
import { getToken, setToken, nameToken} from '../../utiles/token';
import {infoSwal} from '../../utiles/swal';
import { preload } from '.';
import { types } from '../types/types';


export const login = (usuario, pass) =>{

    return async(dispatch)=>{

        dispatch( preload(true) );//Efecto de carga

        try{
            const form = {
                email:usuario,
                password:pass
            }

            const resp = await fetchCustome({ 
                endpoint : '/auth/login',
                method :'POST',
                body:form,
                json:true,
                token: false
            });

            setToken(resp.authorization.token);
            window.location.reload(false);
        }
        catch(e){
            infoSwal({title:'Datos incorrectos',text:'Verifica tus datos'});
            dispatch(preload(false));//Efecto de carga
        }
        finally{
        }
    } 
}

export const endLogin = () =>{
    return async(dispatch)=>{

        dispatch( preload(true) );//Efecto de carga
        try{
            //Invalidamos el token en el backend
            await fetchCustome({ 
                endpoint : '/auth/logout',
                method :'GET',
            });
        }
        catch(e){
        } 
        finally{
            localStorage.removeItem(nameToken);
            dispatch({
                type: types.logout
            });
            dispatch(preload(false));//Efecto de carga
        }
       
    } 
}

export const checkLogin = () =>{
   
    return async(dispatch)=>{

        dispatch( preload(true) );

        const existe = getToken();
        
        if(existe){
            try{

                const resp = await fetchCustome({ 
                    endpoint : '/auth/me',
                    method :'GET',
                });

                dispatch({
                    type: types.login,
                    payload: {
                        id:resp.id,
                        name:resp.name
                    }
                });

            }
            catch(e){
                infoSwal({title:'La sesión expiró',text:''});
                localStorage.removeItem(nameToken);//Removemos el token en caso de que hubiera caducado o no sea valido
            }
            finally{
                dispatch( preload(false) );//Efecto de carga
            }
        }

       dispatch(preload(false));//Efecto de carga
    } 
}



// export const updateImg = (img) =>{
//     return (dispatch)=>{
//         dispatch(updateImg_(img));     
//     }
// }


// const updateImg_ = ( img ) =>({
//     type: types.changeImg,
//     payload: img
// });