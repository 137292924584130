import { useRef,useState,useContext } from 'react';
import { usePets } from './useDocumentos';
import { Load2 } from '../miscellaneous/load2/Load2';
//import {questionConfirmate,infoSwal} from '../../utiles/swal';
import './moduloDocumentos.css';
import { Modal } from './Modal';
import { AdvanceProvider, ContextAdvance } from './ProviderAdvance';



export const Documentos = ()=>{

    const {resp,setResp} = usePets();

    return(
        <section id="misMascotas">
            <div className="container" >
                <div className="section-title">
                    <h2>Mis registros</h2>
                </div>

                <div className="row">
                    {
                        
                        resp.load

                        ?

                        <Load2 />

                        :
                            resp.data.length === 0 

                            ?

                            <h2 className='text-center mt-5'>No tienes registros</h2>

                            :
                    
                            <AdvanceProvider>
                                {resp.data.map( e => <Card key={e.id} id={e.id} />)}
                            </AdvanceProvider>

                    }

                </div> 

            </div>
        </section>
    )
}

const items = [
    {
        id:1,
        name:'Captura',
        status:'pendiente'
    },
    {
        id:2,
        name:'Revisión Cliente',
        status:'pendiente'
    },
    {
        id:3,
        name:'Dpto. Jurídico',
        status:'pendiente'
    },
    {
        id:4,
        name:'Dpto. Comercial',
        status:'pendiente'
    },
    {
        id:5,
        name:'Dpto. Jurídico',
        status:'pendiente'
    },
    {
        id:6,
        name:'Finalizado',
        status:'pendiente'
    }
];


const Card = ({id})=>{

    const {avance} = useContext(ContextAdvance);

    const [btnModal,setBtnModal] = useState(false);//toggle modal


    const data = useRef({
        id:0,
        number:0,
        title:'',
        status:''
    });

    const onclick = (number,title,status,id)=>{
        
        data.current = {
            id,
            number,
            title,
            status
        }

        setBtnModal(true);
    }

    return(
        <div className="col-md-12 mb-5">
            <div className="card male">
                <div className="card-body">

                    <div className='row'>
                        {
                            items.map( (item,index) => (
                                <div key={item.id} className={`col-md-2 col-xs-3  ${index === 0 ? 'paso-modulo-inicio' : index === 5 ? 'paso-modulo-final' : 'paso-modulo'}`}>
                                    <div className='number'>{item.id}</div>
                                    <div className='mb text-truncate'>{item.name}</div>
                                    <button type="button" className="btn btn-primary mt-2" onClick={()=>onclick(item.id,item.name,item.status,id)}>Ver más</button>
                                </div>
                            ))
                        }
                    </div>

                    <div className='mt-4'>
                        <div>Avance</div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width:(avance * 16.66)+'%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>  

                   
                    { btnModal && <Modal modal={btnModal} setModal={setBtnModal} data={data} /> }
                    
                </div>
            </div>
        </div>
    );
}
