
import { useContext,useState, Fragment} from "react";
import { Document,pdfjs,Page  } from 'react-pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.min.mjs";
import { ContextChangeWidth } from "../../../contexts/ContextChangeWidth";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './viewerPdfCrow.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.mjs',
//     import.meta.url,
// ).toString();

const optionsPdf = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};


export const PdfViwCrow = ({url,close})=>{

    const {widthScreen} = useContext(ContextChangeWidth);

    const [numPages, setNumPages] = useState()
    const [pageNumber, setPageNumber] = useState(1);
    const [zoom, setZoom] = useState(1.);


    const onDocumentLoadSuccess = (nextNumPages)=>{
        setNumPages(nextNumPages._pdfInfo.numPages);
    }

    const nextPage = ()=>{
        if(pageNumber < numPages )
            setPageNumber(pageNumber + 1)
    }

    const previousPage = ()=>{
        if(pageNumber > 1)
            setPageNumber(pageNumber - 1)
    }

    const zoomMax = ()=>{
        if(zoom < 2)
            setZoom(zoom + .25)
    }

    const zoomMin = ()=>{
        if(zoom > 1)
            setZoom(zoom - .25)
    }

    return(
        <div className="viewerPdfCrow">
            <div className="container-viewerPdfCrow">
                <Document file={url.current} onLoadSuccess={onDocumentLoadSuccess} options={optionsPdf}>
                    <Page
                        pageNumber={pageNumber}
                        width={widthScreen ? Math.min(widthScreen, 700) : 700}
                        scale={zoom}
                    />
                </Document>
            </div>

            <div className="d-flex">
                <button className="btn btn-outline-light me-3" onClick={zoomMin}><i className="fas fa-search-minus"></i></button>
                <button className="btn btn-outline-light me-2" onClick={zoomMax}><i className="fas fa-search-plus"></i></button>
                <p style={{color:'#fff'}}>
                    Page {pageNumber} of {numPages}
                </p>
                <button className="btn btn-outline-light ms-2" onClick={previousPage}><i className="fas fa-chevron-left"></i></button>
                <button className="btn btn-outline-light ms-3" onClick={nextPage}><i className="fas fa-chevron-right"></i></button>
            </div>

            <i className="fas fa-times close" onClick={()=>close(false)}></i>
        </div>
    )
}