import { Fragment, useState,useRef, useContext} from "react";
import {PdfViwCrow} from "../miscellaneous/viewerPdfCrow/PdfViwCrow"
// import { Load2 } from "../miscellaneous/load2/Load2";
import { infoSwal, questionConfirmate, waitSwal, closeSwal,advanceSwal } from "../../utiles/swal";
import { ContextAdvance } from "./ProviderAdvance";
import {files,options} from "./bdTemp"


let contador1 = 4;
let contador2 = 3;

export const Paso1 = ()=>{

    //cargamos las listas del backend
    const [listSelect,setListSelect] = useState(options);

    //el id del nombre que se selecciono para el archivo
    const idNameSelected = useRef(options.length > 0 ? options[0].id : 0);

    return (
        <>
            <Seccion1 />
            <Seccion2 listSelect={listSelect} setListSelect={setListSelect} idNameSelected={idNameSelected}/> 
            <Seccion3 listSelect={listSelect} idNameSelected={idNameSelected}/>
        </>
    )
}

const Seccion1 = ()=>{
     //Control del avance de porcentaje
     const {avance,setAvance} = useContext(ContextAdvance);

    const avanceIncrement = ()=>{
        if(avance <= 1){
            if(avance === 0)
                setAvance(1)
            else
                setAvance(0)
        }
    }

    return(
        <div className='row mt-3 mb-3 seccion'>
            <div className='col-9 text-start'>
                <span className="text-uppercase title">Marcar la casilla al finalizar la carga total</span>
            </div>
            <div className='col-3 text-end' >
                <i className={`${avance > 0 ? 'far fa-check-square' : 'far fa-square'} fa-2x`} style={{cursor:'pointer', padding:3}} onClick={avanceIncrement}></i>
            </div>
            <div className='col-12'>
                <hr />
            </div>
        </div>
    )
}

const Seccion2 =({listSelect,setListSelect,idNameSelected})=>{

    //control para el show/hide de las pestañas
    const [btnNombres,setBtnNombres] = useState(false);

    //el valor del único input
    const [form,setForm] = useState("");

    //el id delitem a actualizar
    const idUpdate = useRef(0);

    //Actualiazar o agregar un nuevo nombre a la lista de posibles archivos
    const btnAddField = ()=>{

        let newArray = [];

        if(form.trim() === "")
            return;

        if(idUpdate.current > 0){

            const exists = listSelect.find( select => select.name.toLowerCase() === form.trim().toLowerCase() && select.id !== idUpdate.current );

            if(exists)
                return infoSwal({title:'El nombre ya existe',text:''});

            newArray = listSelect.map(select=>{

                if(select.id === idUpdate.current){
                    return{
                        ...select,
                        name:form.trim()
                    }
                }
                return select;
            });

            if(idUpdate.current > 0)
                idUpdate.current = 0;
        }

        else{
            const exists = listSelect.find( select => select.name.toLowerCase() === form.trim().toLowerCase());

            if(exists)
                return infoSwal({title:'El nombre ya existe',text:''});
    
            newArray = [
                ...listSelect,
                {
                    id:contador1++,
                    name:form.trim()
                }
            ]
        }

        newArray = newArray.sort((a,b)=>{
            if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase())
                return 1;
            if(a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase())
                return -1;
            return 0;
        });

        idNameSelected.current = newArray[0].id

        console.log(newArray);

        setListSelect(newArray);
        setForm("");

    }

    const btnUpdateField = (file)=>{
        idUpdate.current = file.id;
        setForm(file.name)
    }

    return(
        <div className='row seccion'>
            <div className='col-9 text-start'>
                <span className="text-uppercase title">Administrar nombres</span>
            </div>
            <div className='col-3 text-end'>
                <i className={`fas fa-2x ${btnNombres ? 'fa-caret-square-up' : 'fa-caret-square-down'}`} style={{cursor:'pointer', padding:6}} onClick={()=>setBtnNombres(!btnNombres)}></i>
            </div>
            <div className='col-12'>
                <hr />
            </div>

            {
                btnNombres

                &&

                <>

                    <div className='col-sm-8 col-7 text-start'>
                        <input className="form-control" value={form} onChange={(e)=>{setForm(e.target.value)}} placeholder="..."/>
                        
                    </div>
                    <div className='col-sm-4 col-5 text-end  mb-2'>
                        <button type="button" className={`btn ${ idUpdate.current === 0 ? 'btn-dark' : 'btn-primary'}`} onClick={btnAddField}> { idUpdate.current === 0 ? 'Agregar' : 'Actualizar'}</button>
                    </div>

                    <div className='col-12'>
                        <hr />
                    </div>

                    {

                        listSelect.map( (file,index) =>{
                            return(
                                <Fragment key={file.id}>
                                    <div className='col-7 text-start text-truncate'>
                                        {index + 1}.-{file.name}
                                    </div>
                                    <div className='col-5 text-end'>
                                        <button className="btn btn-outline-secondary me-2" onClick={()=>btnUpdateField(file)}>
                                            <i className="fas fa-pencil-alt"></i>
                                        </button>
                                        {/* <i className="fas fa-2x fa-trash-alt me-4 text-danger" style={{cursor:'pointer'}}></i> */}
                                    </div>
                                    <div className='col-12'>
                                       <hr style={{opacity:'.1'}}/>
                                    </div>
                                </Fragment>
                            )
                        })
                    }

                </>
            }
        </div>
    )
}

const Seccion3 = ({listSelect,idNameSelected})=>{

    //la lista de archivos de la BD
    const [listFiles,setListFiles] = useState(files);

    //visor de pdfs
    const [btnViewer,setBtnViewer] = useState(false)

    //control para el show/hide de las pestañas
    const [btnDocumentos,setBtnDocumentos] = useState(false);

    //referencia a nuestro input file 
    const btnLoadFile = useRef(null);

    //Hacemos referencia a la url de pdf que queremos leer
    const url = useRef(null);

    const showPdfViwCrow = (url_)=>{
        url.current = url_
        setBtnViewer(true);
    }

    const onChangeFile = ()=>{
        btnLoadFile.current.value = "";

        waitSwal();

        setTimeout(()=>{

            const option = listSelect.find( option => option.id === idNameSelected.current);

            const newArray = [
                ...listFiles,
                {
                    id:contador2++,
                    name:option.name
                }
            ];

            newArray.sort((a,b)=>{
                if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase())
                    return 1;
                if(a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase())
                    return -1;
                return 0;
            });

            setListFiles(newArray)

            closeSwal();
        },2000)

    }

    const btnDeleteFile = (id)=>{

        console.log('eliminar: ' + id);

        const option = listFiles.find( option => option.id === id);

        questionConfirmate(`Deseas eliminar el archivo: ${option.name}` ,(resp)=>{
            if(resp){
                const newArray = listFiles.filter( option => option.id !== id);
                setListFiles(newArray)
            }
        });
    }

   
   
    return(
        <div className='row mt-3 seccion'>
            <div className='col-9 text-start'>
                <span className="text-uppercase title">Administrar Documentos</span>
            </div>
            <div className='col-3 text-end'>
                <i className={`fas fa-2x ${btnDocumentos ? 'fa-caret-square-up' : 'fa-caret-square-down'}`} style={{cursor:'pointer', padding:3}} onClick={()=>setBtnDocumentos(!btnDocumentos)}></i>
            </div>
            <div className='col-12'>
                <hr />
            </div>
            
            {
                btnDocumentos

                &&
    
                <>
                    <div className='col-sm-8 col-7 text-start'>
                        <select className="form-control" onChange={(e)=>{idNameSelected.current = parseInt(e.target.value)}}>
                            {
                                listSelect.map(option =>(<option key={option.id} value={option.id}>{option.name}</option>))
                            }
                        </select>
                    </div>
                    

                    <div className='col-sm-4 col-5 text-end  mb-2'>
                        <input ref={btnLoadFile} type="file" className="btn btn-dark" style={{display:'none'}} onChange={onChangeFile}/>
                        <button type="button" className="btn btn-dark" onClick={()=>{btnLoadFile.current.click()}}>Cargar</button>
                    </div>

                    <div className='col-12'>
                        <hr />
                    </div>

                    {

                        listFiles.map( (file,index) =>{
                            return(
                                <Fragment key={file.id}>
                                    <div className='col-sm-7 col-5 text-start text-truncate'>
                                        {index + 1}.-{file.name}
                                    </div>
                                    <div className='col-sm-5 col-7 text-end'>
                                         <button className="btn btn-outline-success me-2" onClick={()=>advanceSwal(file.name, file.url)}>
                                            <i className="fas fa-download"></i>
                                        </button> 
                                        <button className="btn btn-outline-primary me-2" onClick={()=>showPdfViwCrow(file.url)}>
                                            <i className="fas fa-search"></i>
                                        </button>
                                        <button className="btn btn-outline-danger" onClick={()=>btnDeleteFile(file.id)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                            
                                    </div>
                                    <div className='col-12'>
                                       <hr style={{opacity:'.1'}}/>
                                    </div>
                                </Fragment>
                            )
                        })
                    }

                </>         
            }

            {
                btnViewer && <PdfViwCrow close={setBtnViewer}  url={url} />
            }

        </div>
    )
}